import React, { useEffect, useState } from "react"
import { Avatar, Box, Rating, TextField, useMediaQuery } from "@mui/material"
import { Product as BozPayProduct, Order } from "boz.pay.component"
import { api } from "../api"
import ToysIcon from "@mui/icons-material/Toys"
import { ReviewForm } from "../types/server/class/Order"

interface ProductContainerOnModalProps {
    bozPayProduct: BozPayProduct
    confirmed: boolean
    rating?: boolean
    order: Order
    onReviewChange: (review: ReviewForm) => void
}

export const ProductContainerOnModal: React.FC<ProductContainerOnModalProps> = ({
    bozPayProduct: bozPayProduct,
    onReviewChange,
    confirmed,
    order,
}) => {
    const isMobile = useMediaQuery("(orientation: portrait)")
    const [rating, setRating] = useState(0)
    const [review, setReview] = useState("")
    const [product, setProduct] = useState<Product>()
    const fetchProduct = async () => {
        try {
            const response = await api.post("/products/id", { id: bozPayProduct.referenceId, franchise: order.store.split("-")[1] })
            setProduct(response.data)
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchProduct()
    }, [])

    useEffect(() => {
        onReviewChange({ orderId: Number(order.referenceId), rating, comment: review, referenceId: Number(bozPayProduct.referenceId) })
    }, [rating, review])

    return (
        <Box sx={{ display: "contents" }}>
            <Box
                sx={{
                    color: "gray",
                    gap: "2vw",
                    justifyContent: "center",
                    width: 1,
                    alignItems: "space-between",
                    marginTop: "2vw",
                    flexDirection: "column",
                }}
            >
                <Box sx={{ flexDirection: "row", gap: "2vw", alignItems: "center", width: 1 }}>
                    <Avatar
                        src={`data:image/jpg;base64,${product?.cover}`}
                        variant="rounded"
                        sx={{ width: isMobile ? "18vw" : "5vw", height: isMobile ? "18vw" : "5vw", objectFit: "contain" }}
                    >
                        <ToysIcon style={{ height: "75%", width: "75%" }} />
                    </Avatar>
                    <p
                        style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden", // Garante que o conteúdo que excede a largura seja escondido
                            whiteSpace: "nowrap",
                            fontSize: "0.9rem",
                        }}
                    >
                        {bozPayProduct.name}
                    </p>

                    {confirmed && !isMobile && (
                        <Rating
                            value={rating}
                            onChange={(_, value) => setRating(value || 0)}
                            sx={{
                                marginLeft: "auto",
                                fontSize: "1.3rem",
                                "& .MuiRating-iconFilled": {
                                    color: "primary.main",
                                },
                                "& .MuiRating-iconHover": {
                                    color: "primary.main",
                                },
                            }}
                        />
                    )}
                    {!confirmed && <p style={{ marginLeft: "auto" }}>x {bozPayProduct.quantity}</p>}
                </Box>
                {confirmed && isMobile && (
                    <Rating
                        value={rating}
                        onChange={(_, value) => setRating(value || 0)}
                        sx={{
                            alignSelf: "center",
                            fontSize: "2.5rem",
                            "& .MuiRating-iconFilled": {
                                color: "primary.main",
                            },
                            "& .MuiRating-iconHover": {
                                color: "primary.main",
                            },
                        }}
                    />
                )}
                {confirmed && (
                    <TextField
                        value={review}
                        onChange={(event) => setReview(event.target.value)}
                        label="Comentário"
                        multiline
                        maxRows={3}
                        minRows={2}
                    />
                )}
            </Box>
        </Box>
    )
}
