import React, { useEffect, useState } from "react"
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    useMediaQuery,
} from "@mui/material"
import { Order } from "boz.pay.component"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { api } from "../api"
import { ProductContainerOnModal } from "./ProductContainerOnModal"
import { CurrencyText } from "./CurrencyText"
import { useFormik } from "formik"
import { ReviewForm } from "../types/server/class/Order"

interface OrderModalProps {
    order: Order
    externallyOpen?: boolean
    setExternallyOpen?: React.Dispatch<React.SetStateAction<boolean>>
    updateOrder?: () => Promise<void>
}

export const OrderModal: React.FC<OrderModalProps> = ({ order, externallyOpen, setExternallyOpen, updateOrder }) => {
    const isMobile = useMediaQuery("(orientation: portrait)")
    const [isOpen, setIsOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [confirmed, setConfirmed] = useState(order.status === "Concluído")

    const refreshOrder = async () => {
        if (!updateOrder) return
        updateOrder()
    }

    const reviewFormik = useFormik<{ reviews: ReviewForm[] }>({
        initialValues: { reviews: [] },
        async onSubmit(values, formikHelpers) {
            console.log(values)

            if (loading) return
            setLoading(true)
            try {
                const response = await api.post("/order/review", values)
                if (response.status == 200) {
                    refreshOrder()
                    setIsOpen(false)
                }
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false)
            }
        },
    })

    const onConfirm = async () => {
        // if (loading) return

        const data: { bozpay_id: number; reference_id: string } = {
            bozpay_id: order.id,
            reference_id: order.referenceId,
        }

        setLoading(true)
        try {
            const response = await api.post("/order/confirm_receiving", data)
            if (response.status == 200) {
                console.log("success")
                setConfirmed(true)
                refreshOrder()
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const onReviewChange = (review: ReviewForm) => {
        const values = [...reviewFormik.values.reviews.filter((item) => item.referenceId !== review.referenceId), review]
        reviewFormik.setFieldValue("reviews", values)
    }

    useEffect(() => {
        if (externallyOpen !== undefined) {
            setIsOpen(externallyOpen)
        }
    }, [externallyOpen])

    useEffect(() => {
        if (!isOpen && setExternallyOpen) {
            setExternallyOpen(false)
        }
    }, [isOpen])

    return (
        <Dialog
            open={isOpen}
            onClose={() => setIsOpen(false)}
            sx={{
                "& .MuiDialog-paper": {
                    width: "90%",
                    borderRadius: isMobile ? "3vw" : "1vw",
                    gap: "2vw",
                },
            }}
        >
            <DialogTitle sx={{ fontWeight: "bold", bgcolor: "#EAECEF" }}>Pedido #{order.referenceId}</DialogTitle>
            <DialogContent sx={{ flexDirection: "column" }}>
                <DialogContentText sx={{ fontSize: "1.1rem", color: "primary.main", marginTop: "2vw" }}>
                    {confirmed ? "Avaliar os produtos" : "Você recebeu seu pedido?"}
                </DialogContentText>
                <Accordion sx={{ backgroundColor: "transparent", boxShadow: "none", flexDirection: "column", padding: 0, width: 1 }} defaultExpanded>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{ width: 1, padding: 0, color: "gray", textDecoration: "underline" }}
                    >
                        Resumo do pedido
                    </AccordionSummary>
                    <AccordionDetails sx={{ gap: "4vw", padding: 0, width: 1 }}>
                        <Box sx={{ flexDirection: "column", gap: "1vw", alignItems: "center", p: 0 }}>
                            {order.products.slice(0, 3).map((product) => (
                                <ProductContainerOnModal
                                    bozPayProduct={product}
                                    key={product.id}
                                    onReviewChange={onReviewChange}
                                    confirmed={confirmed}
                                    rating={confirmed}
                                    order={order}
                                />
                            ))}
                            {order.products.length > 3 && (
                                <Box
                                    sx={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        p: 0,
                                        marginTop: "3vw",
                                    }}
                                >
                                    <p
                                        style={{
                                            width: "100%",
                                            fontSize: "0.8rem",
                                            marginTop: "2vw",
                                            textAlign: "right", // Alinha o texto à direita dentro do <p>
                                        }}
                                    >
                                        ... outros produtos
                                    </p>
                                </Box>
                            )}
                        </Box>
                    </AccordionDetails>
                </Accordion>
                {!confirmed && (
                    <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end", p: 0, marginTop: "3vw" }}>
                        <p style={{ margin: 0 }}>
                            <span style={{ fontWeight: "bold" }}>Total:</span> <CurrencyText value={order.total} />
                        </p>
                    </Box>
                )}
            </DialogContent>
            <DialogActions sx={{ padding: isMobile ? "3vw" : "1vw" }}>
                <Button onClick={() => setIsOpen(false)}>não</Button>
                <Button variant="contained" onClick={confirmed ? () => reviewFormik.handleSubmit() : onConfirm}>
                    {loading ? <CircularProgress size={"1.5rem"} sx={{ color: "white" }} /> : confirmed ? "Enviar avaliação" : "Sim, Recebi"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
