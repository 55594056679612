import React, { useEffect } from "react"
import { Routes as ReactRoutes, Route, useLocation } from "react-router-dom"
import { Home } from "./pages/Home"
import { Results } from "./pages/Results"
import { ProductPage } from "./pages/ProductPage/ProductPage"
import { Checkout } from "./components/Checkout"
import { Order } from "./pages/Order"
import { Login } from "./pages/Login"
import { FirstLogin } from "./pages/FirstLogin"
import { Collections } from "./pages/Collections"
import { Categories } from "./pages/Categories"
import { Orders } from "./pages/Orders"
import { Profile } from "./pages/Profile"
import { FirstPassword } from "./pages/FirstPassword"
import { LoginCheck } from "./pages/LoginCheck"
import { DataDeletionInformation } from "./pages/DataDeletionInformation"
import { NotAvailable } from "./pages/NotAvailable/NotAvailable"
import { useFranchise } from "./hooks/useFranchise"

interface RoutesProps {}

export const Routes: React.FC<RoutesProps> = ({}) => {
    const pathname = useLocation().pathname

    const { franchise } = useFranchise()

    useEffect(() => {
        window.scrollTo({ behavior: "smooth", top: 0 })
    }, [pathname])

    return franchise ? (
        franchise.active ? (
            <ReactRoutes>
                <Route index element={<Home />} />
                <Route path="/search" element={<Results />} />
                <Route path="/search/:type" element={<Results />} />
                <Route path="/search/:type/:value" element={<Results />} />
                <Route path="/product/:id" element={<ProductPage />} />
                <Route path="/checkout" element={<Checkout />} />
                <Route path="/order/:id" element={<Order />} />

                <Route path="/signup" element={<p>(Página de cadastro ainda não criada.)</p>} />
                <Route path="/login" element={<Login />} />
                <Route path="/login_checker" element={<LoginCheck />} />
                <Route path="/first_login" element={<FirstLogin />} />
                <Route path="/first_password/:hash" element={<FirstPassword />} />

                <Route path="/collections" element={<Collections />} />
                <Route path="/categories" element={<Categories />} />
                <Route path="/orders" element={<Orders />} />
                <Route path="/profile" element={<Profile />} />

                <Route path="/not-available" element={<NotAvailable />} />

                <Route path="data_deletion" element={<DataDeletionInformation />} />

                <Route
                    path="*"
                    element={
                        <div style={{ justifyContent: "center", alignItems: "center", display: "flex", height: "100vh", fontSize: "5vw" }}>404</div>
                    }
                />
            </ReactRoutes>
        ) : (
            <ReactRoutes>
                <Route path="/*" element={<NotAvailable />} />
            </ReactRoutes>
        )
    ) : (
        <ReactRoutes>
            <Route path="/*" element={<Home />} />
        </ReactRoutes>
    )
}
